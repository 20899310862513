<!--=========================================================================================
  File Name: GameRoom.vue
  Description: Game Room Component
==========================================================================================-->
<template>
<div :class="{ 'locked': game.isLocked}" class="room-container-bull" @mouseover="mouseIn" @mouseleave="mouseLeave">
    <b-overlay :show="show && !game.isLocked" rounded="sm" class="custom-overlay" variant="dark">
        <b-button class="play-game" @click="redirectGame(game.game, game.roomno, isMobile())" v-show='show && !game.isLocked'>{{ $t('lobby.baccarat') }}</b-button>
        <div v-if="game.isLocked" class="locked-text">
            <h6>{{ $t('gameroom.closeTextHeader') }}</h6>
            <div>{{ $t('gameroom.closeTextTop') }}</div>
            <div>{{ $t('gameroom.closeTextBot') }}</div>
        </div>
        <div v-if="!game.isLocked" class="room-info">
            <div class="room-details">
                <div class="shoe-info" style="height: 81%;display: block;overflow: hidden">
                    <div class='bull-shoe-pos-lobby' style="margin-left: -30px;">
                        <result-road :shoeResults="game.shoe.length > 0 ?  (game.shoe).slice(0, -1) : '|||'" />
                    </div>
                </div>
                <div class="details">
                    <div class="room-time">{{ roomCount + 1 }}</div>
                    <div class="room-round" v-bind:style="{width: roomRoundW + 'vw'}">{{ game.roomno + ' : ' + getRoundNumber(game.roomorder) }}</div>
                    <div class="room-time room-time-new bulltimer"><timer :timeLeft="gameTimer" :gameType="'BULL'" /></div>
                </div>
            </div>
            <div class="logo" :style="{ backgroundImage: `url(${game.lobby_img_url})` }"></div>
        </div>
        <div class="overlay">
        </div>
    </b-overlay>
</div>
</template>

<script>
import ResultRoad from '@/components/bull/roads/mobile/ResultRoadLobby';
import Timer from '@/components/common/LobbyTimer'

export default {
    name: 'GameRoom',
    components: {
        ResultRoad,
        Timer
    },
    created() {
        this.resizeRoundView();
    },
    data() {
        return {
            show: false,
            gameTimer: this.game.timer || 0,
            roomRoundW: 0,
        }
    },
    props: {
        game: {
            required: true,
            type: Object
        },
        level: {
            required: true,
            type: Object,
            default: () => ({
                player_min: 0,
                player_max: 0
            })
        },
        lobbyImg: {
            required: true,
            type: String
        },
        selectedCols: {
            required: true,
            type: Number
        },
        roomCount: {
            required: false,
            type: Number
        },
    },
    watch: {
        game: {
            handler(newVal) {
                var vm = this;
                if (vm.gameTimer == 0) {
                    vm.gameTimer = newVal.timer;
                }
            },
            deep: true
        },
        gameTimer: {
            handler(val) {
                if (val > 0) {
                    setTimeout(() => {
                        this.gameTimer--;
                    }, 1000);
                }
            },
            immediate: true,
            deep: true
        },
        selectedCols: {
            handler() {
                this.resizeRoundView();
            }
        },

    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        redirectGame(game, roomno, mobile) {
            this.show = !this.show

            this.$emit('pauseAudio');

            this.$store.commit('gameModule/SET_ROOM_DATA', {
                roomno,
                roomorder: '',
                state: 2,
                shoe: '',
                time: 0,
                result: null
            })

            this.$socket.client.emit('switch_room', {
                roomno,
                level: this.$store.state.gameModule.currentLevel
            })

            if (mobile) {
                this.$router.push(`/m/bull/game/${game}/${roomno}`);
            } else {
                // this.$router.push(`/game/bull/${game}/${roomno}`);
                this.$router.push(`/game/bull/${game}/${roomno}`);
            }
        },
        getRoundNumber(roomorder) {

            try {
                return roomorder.split('-')[1] || 0
            } catch (e) {
                return 0
            }
        },
        mouseIn() {
            this.show = true;
        },
        mouseLeave() {
            this.show = false;
        },
        drawGrids() {
            var lc = new Array(7).fill('').map(() => new Array(6).fill(''));
            return lc;
        },
        resizeRoundView() {
            if (this.selectedCols == 4) {
                this.roomRoundW = 3;
            }
            else if (this.selectedCols == 3) {
                this.roomRoundW = 8;
            } else {
                this.roomRoundW = 12;
            }
        },

    },
}
</script>

<style>
.room-container-bull {
    width: 100%;
    border-radius: 0.5vh;
    background-color: #f6f6f6;
    overflow: hidden;
    height: 24vh;
    border: 1px solid #ff0f0f;
    box-shadow: 0 0 1px white;
}

.bg-dark {
    background-color: rgb(0, 0, 0, 0.7) !important;
}

.play-game {
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 12;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    height: 2.5vw;
    top: 45%;
    width: 5.5vw;
    color: #ffcf39;
    border: 1px solid #ffcf39;
    background-color: rgb(0, 0, 0, 0.2) !important;
    font-size: 2vh;
}

.rs-ctnr-t {
    width: 100%;
    position: absolute;
    top: 0px;
}

.grids {
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}

.div-left {
    float: left;
}

.bet-limit-div {
    margin-top: -5px;
}

.results-cnt {
    background-color: #c3ab7f;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.bull-shoe-pos {
    display: inline-block;
}

/* SAMSUNG & IPHONE */
@media screen and (max-width: 820px) and (orientation: landscape) {
    .bull-shoe-pos {
        margin-left: -30px !important;
    }

    .details {
        border-radius: 0 !important;
    }

    .adjust_top_text {
        top: 3px !important;
    }

    .title-shoe {
        font-size: 1.5vh !important;
    }
}
</style>
