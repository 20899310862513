<template>
  <div v-bind:class="[isMobile() ? 'base-timer-lobby-mobile' : 'base-timer-lobby']">
    <svg
      class="base-timer-lobby__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer-lobby__circle">
        <circle
          class="base-timer-lobby__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        ></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer-lobby__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span v-bind:class="[isMobile() ? 'base-timer-lobby__label-mobile' : 'base-timer-lobby__label']">{{ timeLeft }}</span>
  </div>
</template>

<script>
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

export default {
  data() {
    return {
      circleDasharray: "",
    };
  },

  props: {
    timeLeft: {
      required: true,
      type: Number,
    },
  },

  computed: {
    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-timer-lobby {
  position: relative;
  width: 1.5vw;
  height: 1.5vw;
  font-family: "DS-Digital";
  src: url("/assets/fonts/DS-DIGIB.TTF") format("truetype");
  font-weight: bold;
  font-style: bold;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8vw;
  }

  @media only screen and (max-width: 1920px) {
    &__label {
      top: 0.2vw;
    }
  }

  @media only screen and (max-width: 1600px) {
    &__label {
      top: 0.3vw;
    }
  }

  @media only screen and (max-width: 1440px) {
    &__label {
      top: 0.5vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    &__label {
      top: 0.7vw;
    }
  }
}

.base-timer-lobby-mobile {
  position: relative;
  width: 6.5vw;
  height: 6.5vw;
  font-family: "DS-Digital";
  src: url("/assets/fonts/DS-DIGIB.TTF") format("truetype");
  font-weight: bold;
  font-style: bold;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  .base-timer-lobby__label-mobile {
    position: absolute;
    width: 7vw;
    height: 7vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw;
  }

  @media only screen and (max-width: 1920px) {
    .base-timer-lobby__label-mobile {
      top: 0.2vw;
    }
  }

  @media only screen and (max-width: 1600px) {
    .base-timer-lobby__label-mobile {
      top: 0.3vw;
    }
  }

  @media only screen and (max-width: 1440px) {
    .base-timer-lobby__label-mobile {
      top: 0.5vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    .base-timer-lobby__label-mobile {
      top: 0.7vw;
    }
  }
}
</style>
